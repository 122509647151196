










































































import { Component, Vue } from 'vue-property-decorator';
import FileUploadModule from '@/store/modules/FileUploadModule';
import router from '@/router';

@Component
export default class Importfileupload extends Vue {
  file: any = null;

  async fileInput() {
    const formData = new FormData();
    formData.append('file', this.file);

    await FileUploadModule.uploadFile(formData);

    if (FileUploadModule.fileUploadSucceeded) {
      router.push(
        '/import/file/processing/' + FileUploadModule.fileUploadGuid,
      );
    }
  }
  fileChange() {
    console.log('fileChange');
    FileUploadModule.resetFileUploadPage();
  }

  get fileUploadError() {
    return FileUploadModule.fileUploadError;
  }

  get fileUploadProcessing() {
    return FileUploadModule.fileUploadProcessing;
  }

  get fileUploadSucceeded() {
    return FileUploadModule.fileUploadSucceeded;
  }
}
